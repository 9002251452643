<template>
  <div class="h5videodiv" @mouseenter="isShow = true" @mouseleave="isShow = false" ref="container">

    <!-- 视频 -->
    <div class="video-container" ref="videoContainerRef">
      <video class="h5video" preload="auto" :id="videoid" ref="h5video" width="500" style="object-fit:fill;"></video>      
    </div>


    <!-- 全屏和关闭按钮 -->
    <div class="btn-div" v-if="isShow && h5handler">
      <span @click="FullScreen($event)" style="margin-right:1vh;"><i class="el-icon-full-screen"></i></span>
      <span @click="closeVideo()"><i class="el-icon-circle-close"></i></span>
    </div>
    
    <!-- 未登录机器人时的播放按钮 -->
    <button
      :disabled="getIsDisabled && isDisable"
      :title="getIsDisabled ? '请先登录机器人' : '点击播放'"
      class="playBtn"
      :class="getIsDisabled && isDisable ?'btn-disable':'' "
      v-if="!isPlay && $hasElement('camera-btn-play')"
      ref="playBtn"
      :id="this.playpauseid"
      @click="PlayVideo(token)"
    > </button>

    <button v-if="isShowMiddleBtn" class="middle-button" @click="handleClick"></button>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import config from "@/http/config.js";
import "@/utils/h5s/adapter";
import { H5sPlayerWS, H5sPlayerHls, H5sPlayerRTC } from "@/utils/h5s/h5splayer";
import { H5sPlayerCreate } from "@/utils/h5s/h5splayerhelper";
import { mapGetters, mapState } from "vuex";
import { openVideoServer } from "@/http/professionalControlApi.js";
import videoDraw from './videoDraw.vue'
export default {
  name: "Liveplayer",

  components: {
    // videoDraw
  },

  props: [
    "h5id",             // 区分每个视频的ID
    "token",            // h5视频播放token
    "isShowMiddleBtn", 
    "isDisable",        
    "isStop",           // 是否停止视频服务
    "videoStatus",      // '' 恢复手动; 0 停止播放; 1 奇臂; 2 偶臂; 3 视觉模式下停止播放
    "videoModeType",    // 左下角视频选择框选的是可见光还是红外
    "isPlayRed"         // 右下角是否开启红外监控
  ],

  data() {
    return {
      /*
       * @Date 2022/06/17 17:22:30
       * 视频状态信息
       */
      isPlay: false,                        // 是否正在播放
      isShow: false,                        // 是否展示监控视频
      playpauseid: "playpause" + this.h5id, // 禁止播放视频按钮ID


      /*
       * @Date 2022/06/17 17:19:47
       * 视频配置项信息
       */
      videoid: "h5sVideo" + this.h5id,      // 视频id
      h5handler: undefined,                 // h5splayer对象     
      
      
      /*
       * @Date 2022/07/21 17:01:46
       * 视频画框信息
       */
      drawData: null,                       // 用于画框的坐标和分辨率
      clearTimer: null,                     // 是否清除
      currentVideoContainer: null,          // 当前包含边框的父级节点
    };
  },

  computed: {
    ...mapGetters(["getIsDisabled"]),
    ...mapState(["session", "keepAlive", "videoTag"]),
  },

  mounted() {
    if(this.videoStatus !== '') {
      this.PlayVideo(this.token);
    }

    // 监听视频全屏切换时，需要红框也要自适应
    this.$refs.videoContainerRef.addEventListener("fullscreenchange", () => {
      this.setBox();
    })
  },

  watch: {
    /*
     * @Date 2022/06/27 11:29:20
     * 
     */
    videoModeType:function(newVal) {
      if(newVal =='可见光' && this.isPlayRed==1) {
        this.PlayVideo(this.token);
      }
    },

    /*
     * @Date 2022/06/17 17:11:58
     * isStop == 1就关掉视频服务
     */
    isStop:function(newVal) {
      if (newVal == 1) {
        setTimeout(()=>{
          this.h5handler.disconnect();
          delete this.h5handler;
          this.h5handler = undefined;
          this.$refs.h5video.load();
          this.$refs.h5video.poster = "";
          this.isPlay = false;
        },800);
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/17 17:27:42
     * @Description token变化了就关掉视频服务
     */
    token: function(newVal) {
      console.log(newVal);
      if (this.h5handler) {
        this.h5handler.disconnect();
        delete this.h5handler;
        this.h5handler = undefined;
        this.$refs.h5video.load();
        this.$refs.h5video.poster = "";
        this.isPlay = false;
      }
      if (this.h5id == "homebigId") {
        this.PlayVideo(newVal);
      }
    },

    /*
     * @Date 2022/07/21 10:34:13
     * 监听画框信息是否更新
     */
    videoTag: function(newVal) {
      if (
        this.h5id == "live2" && 
        this.isPlay &&
        this.videoStatus !== "" &&
        this.videoStatus !== 0
      ) {
        this.AddBox(newVal);
      }
    },


    /*
     * @Date 2022/07/29 13:18:26
     * 监听是否结束了视觉模式
     */
    videoStatus: function(newVal) {
      if(newVal === 0) {
        if(this.h5id === "live2") {
          clearTimeout(this.clearTimer);
          if(this.currentVideoContainer) {
            this.$refs.videoContainerRef.removeChild(this.currentVideoContainer);
            this.drawData = null;
            this.clearTimer = null;
            this.currentVideoContainer = null
          }          
        }
      }
    }
  },

  beforeDestroy() {
    console.log(1);
    if (this.h5handler != undefined) {
      this.h5handler.disconnect();
      delete this.h5handler;
      this.h5handler = undefined;
    }
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/17 16:32:56
     * @Description 开关视频服务(只为手动模式)
     */
    openVideo(status) {
      let cameranum = ''; 

       // "cameranum": 1双臂， 2可见光 ，3红外
       // "status": 0 关 1 开

      if (this.token.match("_live1")) {
        openVideoServer({ cameranum: 1, status });
      } else if (this.token.match("_live2")) {
        openVideoServer({ cameranum: 2, status });
      } else if (this.token.match("_live3")) {
        openVideoServer({ cameranum: 3, status });
        this.$emit("isOpenRed", status);
      }

    },

    /**
     * @Author KR0288
     * @Date 2022/06/17 16:33:04
     * @Description 视频全屏方法
     */
    FullScreen(event) {
      var elem = this.$refs.videoContainerRef;
      if (
        document.fullscreenEnabled || 
        document.webkitFullscreenEnabled || 
        document.mozFullScreenEnabled || 
        document.msFullscreenEnabled
      ) {
        if (
          document.fullscreenElement || 
          document.webkitFullscreenElement || 
          document.mozFullScreenElement || 
          document.msFullscreenElement
        ) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }

        } else {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        }
      } else {
        console.log("Fullscreen is not supported on your browser.");
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/17 17:39:16
     * @Description 播放视频
     */
    PlayVideo(token) {
      console.log(token);

      // 1、开启视频服务
      this.openVideo(1);

      // 2、如果之前已经接收视频流，先关掉
      if (this.h5handler != undefined) {
        this.h5handler.disconnect();
        delete this.h5handler;
        this.h5handler = undefined;
      }

      let conf = null;
      if(this.session) {
        conf = {
          videoid: this.videoid,
          protocol: window.location.protocol,
          host: config.h5sURL,
          rootpath: "/", // '/'
          token: token,
          hlsver: "v1", //v1 is for ts, v2 is for mp4
          // session: "044252f1-3080-4840-8de8-ad7efac3e418" //session got from login
          session: this.session
        };
      }else {
        this.$message.error("请联系后台人员登录机器人")
      }

      // 3、开启接收视频流、播放
      this.h5handler = new H5sPlayerWS(conf);
      this.h5handler.connect();
      var $controls = this.$refs.h5video; // 获取video元素
      $controls.play();
      this.isPlay = true;
    },
    
    /**
     * @Author KR0288
     * @Date 2022/06/20 08:49:44
     * @Description 关闭视频
     */
    closeVideo() {
      this.openVideo(0);
      if (this.h5handler != undefined) {
        this.h5handler.disconnect();
        delete this.h5handler;
        this.h5handler = undefined;
        this.$refs.h5video.load();
        this.$refs.h5video.poster = ""; //关闭视频时是否会黑屏
        this.isPlay = false;
        clearTimeout(this.clearTimer);
        if(this.currentVideoContainer) {
          this.$refs.videoContainerRef.removeChild(this.currentVideoContainer);
          this.drawData = null;
          this.clearTimer = null;
          this.currentVideoContainer = null
        }
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/20 08:49:02
     * @Description 点击按钮更新token
     */
    handleClick() {
      this.$emit("h5sHandle", this.token);
    },

    /**
     * @Author KR0288
     * @Date 2022/07/22 10:57:17
     * @Description 处理画框信息
     */
    handleDrawData() {
      // 1、获取当前视频的分辨率
      let videoTagPosData = [];
      let videoNode = this.$refs.h5video;
      let videoWidth = window.getComputedStyle(videoNode).width.replace("px", "");
      let videoHeight = window.getComputedStyle(videoNode).height.replace("px", "");

      // 2、根据分辨率调整坐标
      this.drawData.position.forEach(item => {
        let tempData = {
          xMin: item.xmin * (videoWidth / this.drawData.resolution.width),
          yMin: item.ymin * (videoHeight / this.drawData.resolution.height),
          xMax: item.xmax * (videoWidth / this.drawData.resolution.width),
          yMax: item.ymax * (videoHeight / this.drawData.resolution.height)
        }
        videoTagPosData.push(tempData)
      })

      return videoTagPosData
    },

    /**
     * @Author KR0288
     * @Date 2022/07/21 10:37:04
     * @Description 创建边框，并添加在视频上
     */
    AddBox(drawData) {

      // 1、处理画框数据
      this.drawData = drawData;
      let videoTagPosData = this.handleDrawData();

      // 2、新增父容器，调整其样式，覆盖视频
      this.currentVideoContainer = document.createElement('div');
      this.setStyleByOne(this.currentVideoContainer, 'position', 'absolute');
      this.setStyleByOne(this.currentVideoContainer, 'width', '100%')
      this.setStyleByOne(this.currentVideoContainer, 'height', '100%')
      this.setStyleByOne(this.currentVideoContainer, 'left', 0)
      this.setStyleByOne(this.currentVideoContainer, 'top', 0)

      // 3、遍历画框数据，新建红框，设置样式，添加到父容器中
      videoTagPosData.forEach(item => {
          let element = document.createElement('div');
          this.setStyleByOne(element, 'position', 'absolute')
          this.setStyleByOne(element, 'left', item.xMin + 'px')
          this.setStyleByOne(element, 'top', item.yMin + 'px')
          this.setStyleByOne(element, 'width', (item.xMax - item.xMin) + 'px')
          this.setStyleByOne(element, 'height', (item.yMax - item.yMin) + 'px')
          this.setStyleByOne(element, 'border', '1px solid red')
          this.currentVideoContainer.appendChild(element) 
      });

      // 5、添加框到视频上，默认10秒后清除框
      this.$refs.videoContainerRef.appendChild(this.currentVideoContainer);
      if(!this.clearTimer) {
        this.clearTimer = setTimeout(() => {
          this.$refs.videoContainerRef.removeChild(this.currentVideoContainer);
          this.currentVideoContainer = null;
          this.clearTimer = null
        }, 4000)        
      }        
    },

    setBox() {
      if(this.currentVideoContainer) {
        let videoTagPosData = this.handleDrawData();
        for(let i = 0; i < videoTagPosData.length; i++) {
          this.setStyleByOne(this.currentVideoContainer.children[i], 'left', videoTagPosData[i].xMin + 'px')
          this.setStyleByOne(this.currentVideoContainer.children[i], 'top', videoTagPosData[i].yMin + 'px')
          this.setStyleByOne(this.currentVideoContainer.children[i], 'width', (videoTagPosData[i].xMax - videoTagPosData[i].xMin) + 'px')
          this.setStyleByOne(this.currentVideoContainer.children[i], 'height', (videoTagPosData[i].yMax - videoTagPosData[i].yMin) + 'px')
        }
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/21 10:37:18
     * @Description 设置单个样式
     */
    setStyleByOne(node, property, value) {
      node.style[property] = value
    },
  }
};
</script>

<style scoped lang="scss">
button {
  border: none;
}
.h5videodiv {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .video-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.h5video {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.h5video::-webkit-media-controls-mute-button {
		display: none;            
}

.h5videodiv > .playBtn {
  width: 3.4vw;
  height: 3.4vw;
  background: url("~@/assets/imgs/green/robot-stop-active.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
}

.h5videodiv > .playBtn.btn-disable {
  cursor: not-allowed;
}

.h5videodiv > .middle-button {
  width: 1.5vw;
  height: 1.5vw;
  background: url("~@/assets/imgs/green/videoBtn.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.btn-div {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  line-height: 20px;
}
.btn {
  display: inline-block;
  color: #fff;
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 2px;
}
/* .photo-snap {
  background: url("~@/assets/imgs/photo-snap.png") no-repeat;
  background-size: 100% 100%;
}
.video-start {
  background: url("~@/assets/imgs/video-start.png") no-repeat;
  background-size: 100% 100%;
}
.video-stop {
  background: url("~@/assets/imgs/video-stop.png") no-repeat;
  background-size: 100% 100%;
} */
/* .video-close {
  background: url("~@/assets/imgs/video-close.png") no-repeat;
  background-size: 100% 100%;
} */
</style>
