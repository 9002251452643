<!--
 * @Author: K0288
 * @Date: 2022-04-19 14:11:12
 * @LastEditTime: 2022-04-21 10:44:59
 * @LastEditors: Please set LastEditors
 * @Description: 渲染视频并画框组件
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\components\videoDraw.vue
-->
<template>
    <source :src="videoSrc"> 
</template>

<script>
export default {
    name: 'videoSource',
    props: ['src', 'drawData'],

    mounted() {
        this.getBoxNode(this.drawData);
    },
    data() {
        return {
            videoSrc: this.src == '' ? 'https://vd2.bdstatic.com/mda-kareu501wkgbwxqz/sc/mda-kareu501wkgbwxqz.mp4?v_from_s=hkapp-haokan-hna&auth_key=1650342205-0-0-bc1e517b34793246702cb925c9666999&bcevod_channel=searchbox_feed&pd=1&cd=0&pt=3&logid=3205725266&vid=4721340502159431239&abtest=100815_1-101454_3-17451_1&klogid=3205725266' : this.src,
        }
    },

    methods: {
        getBoxNode(drawData) {
            let videoContainer = document.createElement('div');
            this.setStyle(videoContainer, 'position', 'absolute');
            this.setStyle(videoContainer, 'width', drawData[0].w + 'px')
            this.setStyle(videoContainer, 'height', '75%')
            this.setStyle(videoContainer, 'left', 0)
            this.setStyle(videoContainer, 'top', 0)
            drawData.forEach(item => {
                let element = document.createElement('div');
                this.setStyle(element, 'position', 'absolute')
                this.setStyle(element, 'left', item.x1 + 'px')
                this.setStyle(element, 'top', item.y1 + 'px')
                this.setStyle(element, 'width', (item.x2 - item.x1) + 'px')
                this.setStyle(element, 'height', (item.y2 - item.y1) + 'px')
                this.setStyle(element, 'border', '1px solid red')
                videoContainer.appendChild(element) 
            });
            console.log(drawData);
            console.log(videoContainer);
            this.$emit('renderBox', videoContainer)
        },

        setStyle(node ,property, value) {
            node.style[property] = value
        }
    }
}
</script>

<style lang="sass" scoped>

</style>